window.$ = require("jquery");
import "ol/ol.css";
import { Map, View } from "ol";
import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import XYZSource from "ol/source/XYZ";
import GeoJSON from "ol/format/GeoJSON";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Overlay from "ol/Overlay";
import { toStringHDMS } from "ol/coordinate";
import { toLonLat } from "ol/proj";
import { fromLonLat } from "ol/proj";
// control
import {
  defaults as defaultControls,
  Attribution,
  FullScreen,
  MousePosition,
  OverviewMap,
  ZoomToExtent,
  ScaleLine,
  ZoomSlider,
} from "ol/control";

var container = document.getElementById("popup");
var content = document.getElementById("popup-content");
var closer = document.getElementById("popup-closer");
/**
 * Create an overlay to anchor the popup to the map.
 */
const overlay = new Overlay({
  element: container,
  autoPan: true,
  autoPanAnimation: {
    duration: 250,
  },
});

closer.onclick = function () {
  overlay.setPosition(undefined);
  closer.blur();
  return false;
};


// base map
var linkgeoserver = "http://5ab0024b5921.sn.mynetname.net:8080/geoserver/gisdataPAO/wms";
const layers = [
  new TileLayer({
    source: new OSM(),
    title: "osm",
    zIndex: 0,
    isBaseLayer: true,
    visible: true,
  }),
  // google xyz tile
  new TileLayer({
    source: new XYZSource({
      url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
    }),
    title: "googlemap",
    zIndex: 0,
    isBaseLayer: true,
    visible: false,
  }),
  // xyz tile
  new TileLayer({
    source: new XYZSource({
      url: "http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
    }),
    title: "Satellite",
    zIndex: 0,
    isBaseLayer: true,
    visible: false,
  }),
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:province",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 0.8,
    title: "province",
    zIndex: 1,
    inBaseLayer: false,
    visible: true,
  }),
        // อำเภอ
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:amphoe",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 0.8,
    title: "amphoe",
    zIndex: 1,
    inBaseLayer: false,
    visible: false,
  }),
        //ตำบล
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:tambon",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 0.8,
    title: "tambon",
    zIndex: 1,
    inBaseLayer: false,
    visible: false,
  }),
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:well",
        TILED: true,
      },
      serverType: "geoserver",
      transition: 0,
    }),
    opacity: 1,
    title: "well",
    zIndex: 2,
    inBaseLayer: false,
    visible: false,
  }),
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:roadpao",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 0.9,
    title: "roadpao",
    zIndex: 1,
    inBaseLayer: false,
    visible: false,
  }),
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:fad",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 1,
    title: "fad",
    zIndex: 1,
    inBaseLayer: false,
    visible: false,
  }),
];

//MousePosition
const mousePosition = new MousePosition();

// zoom slidere
const zoomslider = new ZoomSlider();
// scale line
const scaleLine = new ScaleLine();
// attribution
const attribution = new Attribution({
  collapsible: false,
  // collapsed: true
});
// full screen
const fullScreen = new FullScreen();

const center = fromLonLat([99.948565, 6.844064],'EPSG:3857');
let view = new View({
  center: center,
  zoom: 10,
});

// create map
const map = new Map({
  overlays: [overlay],
  target: "map",
  layers: layers,
  view: view,
  controls: defaultControls({
    attribution: false,
  }).extend([attribution, fullScreen, scaleLine, zoomslider]),
});

map.on("singleclick", (e) => {
  var coordinate = e.coordinate;
  console.log(coordinate);
  content.innerHTML =
    '<p style="color:black">พิกัด:</p><code>' + coordinate + "</code>";
  overlay.setPosition(coordinate);
});

//     map.on('singleclick', function (evt) {
//
//       const coordinate = evt.coordinate;
//       const hdms = toStringHDMS(toLonLat(coordinate));
// console.log(hdms);
//       content.innerHTML = '<p>You clicked here:</p><code>' + hdms + '</code>';
//       overlay.setPosition(coordinate);
//     });

// attribution
function checkSize() {
  var small = map.getSize()[0] < 1000;
  attribution.setCollapsible(small);
  attribution.setCollapsed(small);
}
window.addEventListener("resize", checkSize);
checkSize();

(function ($) {
  "use strict";

  function createSkinBlock(colors, callback, noneSelected) {
    var $block = $("<select />", {
      class: noneSelected
        ? "custom-select mb-3 border-0"
        : "custom-select mb-3 text-light border-0 " +
          colors[0].replace(/accent-|navbar-/, "bg-"),
    });

    colors.forEach(function (color) {
      var $color = $("<option />", {
        class: (typeof color === "object" ? color.join(" ") : color)
          .replace("navbar-", "bg-")
          .replace("accent-", "bg-"),
        text: capitalizeFirstLetter(
          (typeof color === "object" ? color.join(" ") : color)
            .replace(/navbar-|accent-|bg-/, "")
            .replace("-", " ")
        ),
      });

      $block.append($color);

      $color.data("color", color);

      if (callback) {
        $color.on("click", callback);
      }
    });

    return $block;
  }

  var $sidebar = $(".control-sidebar");

  var $container = $("<div />", {
    class: "p-3 control-sidebar-content",
  });

  $sidebar.append($container);
  //ชั้นแผนที่
  
  $container.append(`
    <h5>ชั้นข้อมูลแผนที่</h5><hr class="mb-2"/>
    <h6>แผนที่ฐาน</h6>
    <input type="radio" value="osm" name="radio" checked> <label class="form-check-label">OSM</label>
    <br><input type="radio" value="googlemap" name="radio"> <label class="form-check-label">Google</label>
    <br><input type="radio" value="Satellite" name="radio"> <label class="form-check-label">Satellite</label><br><br>
    <h6>ชั้นแผนที่</h6>
    <input type="checkbox" value="province" id="defaultCheck1" checked> <label class="form-check-label">เขตจังหวัด</label>
    <br><input type="checkbox" value="amphoe" id="defaultCheck2"> <label class="form-check-label" for="defaultCheck2">เขตอำเภอ</label>
    <br><input type="checkbox" value="tambon" id="defaultCheck3"> <label class="form-check-label" for="defaultCheck3">เขตตำบล</label>
    <br><input type="checkbox" value="well" id="defaultCheck2"> <label class="form-check-label">บ่อน้ำตื้น 64</label>
    <br><input type="checkbox" value="fad" id="defaultCheck2"> <label class="form-check-label">ซั้งเชือก</label>
    <br><input type="checkbox" value="roadpao" id="defaultCheck3"> <label class="form-check-label"> ถนน (อบจ.)</label>
  `);

  $(":radio").change((e) => {
    // console.log(e.target.value);
    layers.forEach((l) => {
      if (l.values_.isBaseLayer === true) {
        if (l.values_.title === e.target.value) {
          l.setVisible(true);
        } else {
          l.setVisible(false);
        }
      }
    });
  });

  // overlay layers
  $(":checkbox").click((e) => {
    let chk = e.currentTarget.checked;
    let lyr = e.currentTarget.defaultValue;
    layers.forEach((l) => {
      if (l.values_.title === e.currentTarget.defaultValue) {
        if (e.currentTarget.checked === true) {
          l.setVisible(true);
        } else {
          l.setVisible(false);
        }
      }
    });
  });
})(jQuery);
